import { Injectable } from '@angular/core';
import { isSupported } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { URLS } from './../_config/api.config';
import { ApiService } from './api-data.service';
import { CommonService } from './common.service';
import { CustomFirebaseAnalyticsProvider } from './CustomFirebaseAnalyticsProvider';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root'
})
export class CartService {
	cartList = [];
	cartCount = 0;
	cartProductIds = [];
	shipping_address: any;
	billing_address: any;
	public isloggedIn: boolean = false;
	private subscription: Subscription = new Subscription();

	constructor(
		private dataService: ApiService,
		public commonService: CommonService,
		private toastService: ToastService,
		public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
	) { }

	addIntoCart(data, quantity, is_sample_product?, hideNotification?) {

		const params = {
			"product_id": +data.product_id,
			"total_quantity": parseInt(quantity),
			"is_sample_product": is_sample_product ? is_sample_product : false
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.cartAdd, params).subscribe(data => {
					if (data && data['code'] == 200) {
						isSupported().then(() => {
							this.customFirebaseAnalytics.trackEvent('Add to Cart', +data.product_id);  //Google Analytics Log In Event
						})
						this.getCart();
						resolve(data);
					} else {
						if (!hideNotification) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
						reject(new Error("Failed to add to cart"));
					}
				}, error => {
					if (!hideNotification) {
					}
					reject(error);
				})
			)
		});
	}

	updateCart(cart_id, data) {
		const params = {
			"cart_id": data.cart_id,
			"total_quantity": parseInt(data.total_quantity),
			"is_sample_product": data.is_sample_product,
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.put(URLS.cartUpdate, params).subscribe(data => {
					if (data && data['code'] == 200) {
						resolve(data)
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
						reject(new Error("Failed to update cart"));
					}
				}, error => {
					reject(error);
				})
			)
		});
	}

	deleteCart(data) {
		const params = {
			"cart_id": data.cart_id,
			"is_sample_product": data.is_sample_product,
			"is_deleted": true
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.put(URLS.cartUpdate, params).subscribe(data => {
					if (data && data['code'] == 200) {
						resolve(data);
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
						reject(new Error("Failed to delete cart"));
					}
				}, error => {
					reject(error);
				})
			)
		});
	}


	getCart() {
		const token = this.commonService.localStorageGet('accessToken');
		if (!token) {
			console.log("cartservice no token");
			return Promise.reject(new Error("No access token available"));
		}
		let discount_id = this.commonService.localStorageGet('discount_id');
		let discount_code = this.commonService.localStorageGet('discount_code');

		if ((discount_id || discount_code) && (discount_code != "null" ||
			discount_id != "null") && (discount_code != null || discount_id != null)) {
			const params = {};

			if (this.commonService.localStorageGet('shipping_address_id')) {
				params['address_id'] = +this.commonService.localStorageGet('shipping_address_id')
			}
			else {
				params['city_id'] = +this.commonService.localStorageGet("shipping_city_id");
				params['latitude'] = +this.commonService.localStorageGet("latitude");
				params['longitude'] = +this.commonService.localStorageGet("longitude");
			}

			if (discount_id && discount_id != "null" && discount_id != null) {
				params['coupon_id'] = +discount_id;
			}
			if (discount_code && discount_code != "null" && discount_code != null) {
				params['coupon_code'] = discount_code;
			}

			return new Promise((resolve, reject) => {
				this.subscription.add(
					this.dataService.post(URLS.couponApply, params).subscribe(data => {
						if (data && data['code'] == 200) {
							let cartData;
							this.cartList = [];
							this.cartProductIds = [];
							cartData = data['data'];
							cartData.result.forEach(element => {
								element.products.forEach(element2 => {
									this.cartList.push(element2);
									this.cartProductIds.push(element2.product_id);
								});
							});
							if (cartData.grand_total && cartData.grand_total.length) {
								cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
							}
							this.cartCount = this.cartList.length;
							resolve(cartData);
						} else {
							this.toastService.displayToast(data['message'], null, 'danger');
							reject(new Error("Failed to fetch cart"));
						}
					}, error => {
						console.log(error);
						reject(error);
					})
				)
			})

		}
		else {
			return new Promise((resolve, reject) => {

				let params = {}

				if (this.commonService.localStorageGet('shipping_address_id')) {
					params['address_id'] = this.commonService.localStorageGet('shipping_address_id')
				}
				else {
					params['city_id'] = +this.commonService.localStorageGet("shipping_city_id");
					params['latitude'] = +this.commonService.localStorageGet("latitude");
					params['longitude'] = +this.commonService.localStorageGet("longitude");

				}


				this.subscription.add(
					this.dataService.get(URLS.cartList, params).subscribe(data => {
						if (data && data['code'] == 200) {
							let cartData;
							this.cartList = [];
							this.cartProductIds = [];
							cartData = data['data'];
							if (cartData.grand_total && cartData.grand_total.length) {
								cartData.grand_total.sort((a, b) => a.sequence - b.sequence);
							}
							cartData.result.forEach(element => {
								element.products.forEach(element2 => {
									this.cartList.push(element2);
									this.cartProductIds.push(element2.product_id);
								});
							});
							this.cartCount = this.cartList.length;
							resolve(cartData);
						} else {
							reject(new Error("Failed to fetch cart"));
						}
					}, error => {
						reject(error);
					})
				)
			})
		}


	}

}
