<div class="add-review-container pt-20" [ngClass]="getPlatformService.isDesktop? 'ph-40':null">
	<div class="header-line" *ngIf="!getPlatformService.isDesktop">
	</div>
	<div class="modal-close ion-text-right" (click)="closeModal()">
		<img src="assets/icon/close.svg" alt="Close" height="100%" width="100%" />
	</div>
	<div class="add-review-header">
		{{'rfqBtntext' | translate}}
	</div>
	<div class="horizontal-line" *ngIf="!getPlatformService.isDesktop"></div>

	<div class="flex-row mv-50" *ngIf="getPlatformService.isDesktop">
		<div class="border-div p-15" (click)="chooseRFQOption('mail')">
			<div class="content-div flex-column">
				<img src="assets/icon/mail-rfq.svg" alt="Mail" height="100%" width="100%" class="btn-img" />
				<p class="btn-text mv-10">{{"rfqModal.emailBtnText" | translate}}</p>
			</div>
		</div>

		<div class="border-div p-15 ml-30" (click)="chooseRFQOption('pdf')">
			<div class="content-div flex-column ">
				<img src="assets/icon/pdf-rfq.svg" alt="Download PDF" height="100%" width="100%" class="btn-img" />
				<p class="btn-text mv-10">{{"rfqModal.downloadBtnText" | translate}}</p>
			</div>
		</div>
	</div>

	<div class="flex-column mv-50 width-100 ph-20" *ngIf="!getPlatformService.isDesktop">
		<div class="border-div-mobile p-15" (click)="chooseRFQOption('mail')">
			<div class="content-div-mobile flex-column">
				<img src="assets/icon/mail-rfq.svg" alt="Mail" height="100%" width="100%" class="btn-img" />
				<p class="btn-text mv-10">{{"rfqModal.emailBtnText" | translate}}</p>
			</div>
		</div>

		<div class="border-div p-15 mt-20" (click)="chooseRFQOption('pdf')">
			<div class="content-div-mobile flex-column ">
				<img src="assets/icon/pdf-rfq.svg" alt="Download PDF" height="100%" width="100%" class="btn-img" />
				<p class="btn-text mv-10">{{"rfqModal.downloadBtnText" | translate}}</p>
			</div>
		</div>
	</div>
</div>