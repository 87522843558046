<div class="search-header container">
	<div class="left-block">
		<div [ngClass]="this.routeUrl !='/home' ? 'company-logo pointer':null">
			<a [routerLink]="'/home'">
				<img router-direction="none" src="./assets/icon/companyLogo.svg" height="100%" width="100%"
					alt="company-logo">
			</a>
		</div>
	</div>
	<div class="middle-block" [ngClass]="getPlatformService.isDesktop ? 'ml-16' : 'ml-1p'">
		<app-search-bar id="searchBarWidth" class="bg-transparent"></app-search-bar>
	</div>
	<div class="login-button ml-20" *ngIf="getPlatformService.isDesktop">
		<a href="{{supplierUrl}}" target="_blank" rel="noopener noreferrer"> <ion-button class="sell-btn">
				{{ 'sellBtn' | translate }}
			</ion-button></a>
	</div>

	<div class="mobile-user-block" *ngIf="false && !cartService.isloggedIn && !getPlatformService.isDesktop "
		[ngClass]="commonService.domDirectionLTR ? 'mobile-user-block-ltr' : 'mobile-user-block-rtl'"
		(click)="openModal($event)">
		<img src="./assets/icon/mobilePersonIcon.svg" alt="company-logo">
	</div>

	<div class="mobile-user-block" *ngIf="cartService.isloggedIn  && !getPlatformService.isDesktop && false">
		<img src="./assets/icon/loginuser.svg" alt="company-logo" height="100%" width="100%"
			(click)="openModal($event)">
	</div>
	<div class="notification-icon-mobile" [ngClass]="commonService.domDirectionLTR ? 'ml-10' : 'mr-10'"
		*ngIf="cartService.isloggedIn && !getPlatformService.isDesktop" #notificationElement
		(click)="gotoNotification(true)">
		<img src="./assets/icon/notification_bell.svg" alt="company-logo" height="100%" width="100%">
		<ion-badge mode="ios"
			*ngIf="commonService.notificationCount && commonService.notificationCount < 100">{{commonService.notificationCount}}</ion-badge>
		<ion-badge mode="ios" class="notification-exceed-badge"
			*ngIf="commonService.notificationCount && commonService.notificationCount >= 100">99<sup
				class="plus">+</sup></ion-badge>
	</div>

	<div class="right-block">
		<div class="language-select" [ngClass]="commonService.domDirectionLTR ? '' : 'language-select-rtl'">
			<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
				placeholder="{{'selectOne'|translate}}" [interfaceOptions]="inerfaceOption" interface="popover"
				[(ngModel)]="selectedLanguage" class="pr-15 pt-0 pb-0" (ngModelChange)="languageChanged($event)">
				<ion-select-option *ngFor="let lang of languageArray" [value]="lang">{{lang.title | translate}}
				</ion-select-option>
			</ion-select>
		</div>
		<div class="icon-container">
			<div clickOutside (clickOutside)="outSideDropdown()" class="profile-icon">
				<div class="icon-div-1" *ngIf="cartService.isloggedIn">
					<img src="./assets/icon/loginuser.svg" alt="company-logo" height="100%" width="100%"
						(click)="openModal($event)">
				</div>
				<div class="icon-div" *ngIf="!cartService.isloggedIn">
					<img src="./assets/icon/Guestuser.svg" alt="company-logo" height="100%" width="100%"
						(click)="openModal($event)">
				</div>

				<ion-list class="info-dropdown" [ngClass]="commonService.domDirectionLTR ? 'ltr' : 'rtl'" lines="none"
					*ngIf="isInfoDropdownOpen">
					<ion-item class="dropdown-header">
						<ion-label class="name-class">{{userName}}</ion-label>
					</ion-item>
					<ion-item class="hover-item" (click)="goToCustomerProfile()">
						<ion-label>{{"headerComponent.customerProfile" | translate}}
							<span class="switch-class"> {{"headerComponent.switch" | translate}} </span>
							<img class="right-arrow ml-3"
								[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
								src="assets/icon/arrow-right-blue.svg" alt="juman">
						</ion-label>
					</ion-item>
					<ion-item class="hover-item" (click)="goToAccount()">
						<ion-label>{{"headerComponent.myAccount" | translate}}</ion-label>
					</ion-item>
					<ion-item class="hover-item" (click)="presentAlert()">
						<ion-label>{{"headerComponent.logout" | translate}}</ion-label>
					</ion-item>
				</ion-list>

			</div>

			<div class="notification-icon" *ngIf="cartService.isloggedIn && getPlatformService.isDesktop"
				#notificationElement>
				<img src="./assets/icon/notification_bell.svg" alt="company-logo" height="100%" width="100%"
					(click)="gotoNotification()">
				<ion-badge mode="ios"
					*ngIf="commonService.notificationCount && commonService.notificationCount < 100">{{commonService.notificationCount}}</ion-badge>
				<ion-badge mode="ios" class="notification-exceed-badge"
					*ngIf="commonService.notificationCount && commonService.notificationCount >= 100">99<sup
						class="plus">+</sup></ion-badge>
			</div>

			<div class="cart-icon" #cartElement>
				<img src="./assets/icon/myWishilistIcon.svg" alt="company-logo" height="100%" width="100%"
					(click)="gotoWishList()">
				<!-- <ion-badge *ngIf="cartService.isloggedIn" mode="ios">{{cartService.cartCount}}</ion-badge> -->
			</div>

			<div class="cart-icon" #cartElement>
				<img src="./assets/icon/cartIcon.svg" alt="company-logo" height="100%" width="100%"
					(click)="openCartModal()">
				<ion-badge *ngIf="cartService.isloggedIn" mode="ios">{{cartService.cartCount}}</ion-badge>
			</div>
		</div>
	</div>
</div>
<div clickOutside (clickOutside)="outsideClick()" class="main-header-middle">
	<div class="main-header container">
		<div class="header-left">
			<ion-icon *ngIf="menuIconShow" name="menu-outline" class="menu-icon-web mr-20"
				(click)="toggleAccountMenu()"></ion-icon>

			<div class="products" (click)="toggleMenu()" id="products" [ngClass]="isMenuVisible ? 'bottom-color' : ''">
				<div class="products-icon pr-10">
					<img *ngIf="!isMenuVisible" src="./assets/icon/productsIcon.svg" alt="products-icon" height="100%"
						width="100%">
					<img *ngIf="isMenuVisible" class="products-list-visible" src="./assets/icon/productsIcon.svg"
						alt="products-icon" height="100%" width="100%">
				</div>
				<div class="header-menu" [ngClass]="isMenuVisible ? 'header-menu-visible' : ''">
					{{"headerComponent.products" | translate}}
				</div>
				<div></div>
				<div class="triangle-icon">
					<img *ngIf="!isMenuVisible" src="./assets/icon/downTriangle.svg" alt="triangle-icon" height="100%"
						width="100%">
					<img *ngIf="isMenuVisible" class="rotate-180 menu-visible" src="./assets/icon/downTriangle.svg"
						alt="triangle-icon" height="100%" width="100%">
				</div>
			</div>
			<a class="contact-us" [routerLink]=" '/contact-us'"
				[ngClass]="commonService.domDirectionLTR ? 'productPaddingLeft' : 'productPaddingRight'">
				<div class="contactus-icon pr-10">
					<img class="contact-us" src="assets/icon/contact.svg" alt="Juman" height="100%" width="100%">
				</div>
				<div class="header-menu contact-us-label">
					{{"headerComponent.contact_us" | translate}}
				</div>
			</a>
		</div>
		<div class="header-right">
			<div class="location-info">

				<div *ngIf="userName" class="profile-icon mr-20">
					<div class="profile-div">
						<div class="icon-div">
							<img src="./assets/icon/personIcon-white.svg" alt="company-logo" height="100%" width="100%">
						</div>
						<ion-label *ngIf="userName && cartService.isloggedIn" class="name-class ml-10">
							{{'Hello' | translate}}, {{userName}}
						</ion-label>
					</div>
				</div>

				<div class="location-icon"
					[ngClass]="commonService.domDirectionLTR ? 'locationIconPaddingRight' : 'locationIconPaddingLeft'"
					id="products">
					<img *ngIf="!isLocationLoading" src="./assets/icon/locationIcon.svg" alt="location-icon"
						height="100%" width="100%">
					<ion-spinner *ngIf="isLocationLoading" name="circular"></ion-spinner>
				</div>

				<div class="current-location">
					<p class="current-location-name" (click)="getLocation()">{{commonService.domDirectionLTR
						?currentLocation:currentLocation_ar}}</p>
					<p class="change-location" *ngIf="this.cartService.isloggedIn" (click)="navigatetoChangeLocation()">
						{{"headerComponent.change" |
						translate}}</p>
				</div>
			</div>


		</div>
	</div>
	<app-menus class="desktop-visibility" (childEvent)="isMenuVisible=$event" #child [menuVisibility]="isMenuVisible">
	</app-menus>
</div>
<div class="bread-crumbs container" *ngIf="!isBreadCrumbLoading && breadcrumbsArray.length > 1">
	<ion-breadcrumbs>
		<ng-container *ngFor="let breadcrumb of breadcrumbsArray;index as i;let last = last;">
			<ion-breadcrumb class="bread-crumb-label bread-crumb-with-url pointer" (click)="goToUrl(breadcrumb.url)"
				*ngIf="!last">{{breadcrumb.breadcrumb | translate}}
				<ion-icon class="arrow-right-class" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
					src="assets/icon/arrow-right-blue.svg" alt="juman"></ion-icon>
			</ion-breadcrumb>
			<ion-breadcrumb class="bread-crumb-label bread-crumb-with-url product-detail-url"
				(click)="goToProductListing()"
				*ngIf="!last && i === 1 && routeData && routeData.category != 'products'">{{ routeData.category }}
				<ion-icon class="arrow-right-class" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'"
					src="assets/icon/arrow-right-blue.svg" alt="juman"></ion-icon>
			</ion-breadcrumb>
			<ion-breadcrumb class="bread-crumb-label" *ngIf="last">{{breadcrumb.breadcrumb | translate}}
			</ion-breadcrumb>
		</ng-container>
	</ion-breadcrumbs>
</div>
<div *ngIf="!isHomePage && isBreadCrumbLoading" class="bread-crumbs container">
	<ion-skeleton-text [animated]="true"></ion-skeleton-text>
</div>