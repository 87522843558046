import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { ApiService } from '../../services/api-data.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { DesignAndColorComponent } from '../design-and-color/design-and-color.component';

@Component({
	selector: 'app-select-variant',
	templateUrl: './select-variant.component.html',
	styleUrls: ['./select-variant.component.scss'],
	providers: [NavParams]
})
export class SelectVariantComponent implements OnInit {
	public isLoading = false;
	private subscription: Subscription = new Subscription();

	@Input() productId: any;
	@Input() subProductId: any;
	mainProductData: any;
	imagesArray: any = [];
	productImagesArray: any = [];
	configDataOptions: any;
	selectedConfigData = 0;
	selectedConfigValue: any;
	selectedId: any;
	subProduct: any;
	selected_subproduct: any;
	selectedConfigurationId: any = [];
	configData = [];
	productValue: any = 1;
	subClassId: any;
	pre_selected_subproduct_id: any;

	product_price: any;
	final_price: any;
	discount_type: any;
	discount_value: any;
	is_discount_applied: boolean;
	is_sold_out: any;
	productNotExist: boolean;
	total_quantity: any;
	sold_quantity: any;
	available_quantity: any;
	min_quantity: any;
	availableQuantErr = false;
	minQuantErr = false;

	cart_quantity: any;

	constructor(private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		private route: ActivatedRoute,
		private navParams: NavParams,
		public router: Router,
		public cartService: CartService,
		@Inject(DOCUMENT) private document: Document,) {
	}

	ngOnInit() {
		let stateData = this.subProductId;
		if (stateData) {
			this.pre_selected_subproduct_id = stateData;
		}
		this.loadAllData();
	}

	loadAllData() {
		this.isLoading = true;
		Promise.all([
			this.getProductDetails(),
		]).then(data => {
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		})
	}

	getProductDetails() {
		let params = {
			product_id: +this.productId ? +this.productId : 0
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.getProductDetail, params).subscribe(data => {
					if (data['code'] == 200) {
						if (Object.keys(data['data']).length === 0) {
							this.toastService.displayToast("No data found", null, 'danger');
							this.closeModal();
						}
						this.mainProductData = data['data'];
						this.imagesArray = data['data']['product_images'];

						if (this.imagesArray && this.imagesArray.length) {
							this.imagesArray.forEach(element => {
								if (this.getPlatformService.isDesktop && element['image_type'] == "web" && element['is_cover_image'] == true) {
									this.mainProductData['product_images_web'] = element.image_url;
								}
								if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile" && element['is_cover_image'] == true) {
									this.mainProductData['product_images_mobile'] = element.image_url;
								}
							});
						}
						let main_config: any = [];
						let value_array: any = [];

						if (data['data']['sub_products'] && data['data']['sub_products'].length) {
							data['data']['sub_products'].forEach(element => {
								if (element.product_configurations && element.product_configurations.length) {
									element.product_configurations.forEach(element2 => {
										value_array.push(JSON.parse(JSON.stringify(element2)));
									});
								}
							});
						}

						let unique: any = [...new Map(value_array.map(item => [item['configuration_value_id'], item])).values()];

						unique.forEach(element => {
							var isPresent = main_config.some(function (el) { return el.configuration_id === element.configuration_id });
							if (!isPresent) {

								const lookup = unique.reduce((a, e) => {
									a[e.configuration_id] = ++a[e.configuration_id] || 0;
									return a;
								}, {});
								main_config.push({
									configuration_id: element.configuration_id,
									configuration_name_ar: element.configuration_name_ar,
									configuration_name_en: element.configuration_name_en,
									value_array: this.getConfigurationValue(unique, element.configuration_id),
									selected_value: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_en,
									selected_id: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_id,
									attributeValue: "0",
								});
							}
						});

						this.configData = main_config;
						this.subProduct = data['data']['sub_products'];

						let preselceted_config: any;
						if (this.subProduct && this.subProduct.length && this.pre_selected_subproduct_id) {
							this.subProduct.forEach(element => {
								if (element.product_id == this.pre_selected_subproduct_id) {
									preselceted_config = element.product_configurations;
								}
							});
						}


						if (this.pre_selected_subproduct_id) {
							preselceted_config.forEach(preselect => {
								main_config.forEach((data) => {
									if (data.configuration_id == preselect.configuration_id) {
										data.value_array.forEach((element, index) => {
											if (element.configuration_value_id == preselect.configuration_value_id) {
												element.select = true;
												data.selected_id = element.configuration_value_id;
												data.selected_value = element.configuration_value_en;
												data.attributeValue = index;
											} else {
												element.select = false;
											}
										});
									}

								});
							})

							this.configData = [...main_config];
							this.selectedConfigurationId = [];
							this.productImagesArray = [];
							this.configData.forEach(element => {
								element.value_array.forEach(valueElement => {
									if (valueElement.select === true) {
										this.selectedConfigurationId.push(valueElement.configuration_value_id)
									}
								});
							});
							this.subProductSelection();
						}
						else {
							if (this.getPlatformService.isDesktop) {
								main_config.forEach((configElement) => {
									this.selectedConfigurationId.push(configElement.value_array[0].configuration_value_id)
									configElement.value_array.forEach((valueElement, index) => {
										if (index === 0) {
											valueElement['select'] = true;
										} else {
											valueElement['select'] = false;
										}
									});
								});

							} else {
								main_config.forEach((configElement) => {
									this.selectedConfigurationId.push(configElement.selected_id);
								});
							}

							this.subProductSelection();
						}
						resolve(data);
					} else {
						if (data.code == 500) {
							this.closeModal();
						}
						this.toastService.displayToast(data['message'], null, 'danger');
						reject();
					}
				}, error => {

					if (error.code == 500) {
						this.closeModal();
					}


					reject();
				})
			)
		});
	}

	getConfigurationValue(array, id) {
		let values = [];
		array.forEach(element => {
			if (element.configuration_id == id) {
				delete element['configuration_name_en'];
				delete element['configuration_name_ar'];
				values.push(element);
			}
		});
		return values;
	}

	subProductSelection() {
		this.productNotExist = false;

		let selectedSubProduct = this.subProduct.find(el => {
			let mainIdConfig;
			let subConfigExist
			let idExist;
			this.selectedConfigurationId.forEach(element3 => {
				if (el.product_configurations && el.product_configurations.length) {
					idExist = this.configExitst(el.product_configurations, element3);
				}
				else {
					idExist = null;
				}
				if (idExist == false || !idExist) {
					mainIdConfig = false;
				}
			});

			if (mainIdConfig != false) {
				subConfigExist = true;
			}
			if (subConfigExist == true) {
				return true;
			}
		})
		let configExist: any = null;
		if (!selectedSubProduct || !selectedSubProduct.product_configurations) {
			configExist = false;
		}

		if (selectedSubProduct) {
			this.selected_subproduct = selectedSubProduct;
			this.selected_subproduct['is_sold_out'] = false;

			this.productValue = this.selected_subproduct.min_quantity;
			this.imagesArray = this.selected_subproduct['product_images'];
			if (this.imagesArray && this.imagesArray.length) {
				this.imagesArray.forEach(element => {
					if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
					if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
				});
			}
			else if (this.mainProductData['product_images'] && this.mainProductData['product_images'].length) {
				this.mainProductData['product_images'].forEach(element => {
					if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
					if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
				});
			}

			this.productImagesArray = [...this.productImagesArray];

			this.product_price = this.selected_subproduct.price_per_unit;
			this.final_price = this.selected_subproduct.final_price;
			this.is_discount_applied = this.selected_subproduct.is_discount_applied;
			this.discount_type = this.selected_subproduct.discount_type;
			this.discount_value = this.selected_subproduct.discount_value;
			this.is_discount_applied = this.selected_subproduct.is_discount_applied;
			this.total_quantity = this.selected_subproduct.total_quantity;
			this.sold_quantity = this.selected_subproduct.sold_quantity;
			this.available_quantity = this.selected_subproduct.available_quantity;
			this.cart_quantity = this.selected_subproduct.cart_quantity;
			this.is_sold_out = this.selected_subproduct.is_sold_out;
			this.min_quantity = this.selected_subproduct.min_quantity;

			if (configExist == false) {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;
				this.selected_subproduct.is_sold_out = this.selected_subproduct.is_sold_out;
				this.is_sold_out = this.selected_subproduct.is_sold_out;
			}
			else {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;
			}

		}
		else {
			this.productNotExist = true;
			if (this.mainProductData['product_images'] && this.mainProductData['product_images'].length) {
				this.mainProductData['product_images'].forEach(element => {
					if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
					if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
				});
			}
			this.selected_subproduct.product_name = this.mainProductData.product_name_en;
			this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;

			if (configExist == false) {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;

			}
			else {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;

			}
		}

	}

	subProductStock(product_id) {
		const params = {
			"product_id": product_id,
		}
		this.subscription.add(
			this.dataService.get(URLS.productAvailability, params).subscribe(data => {
				if (data && data['code'] == 200) {
					let result = data['data'];
					this.total_quantity = result.total_quantity;
					this.sold_quantity = result.sold_quantity;
					this.available_quantity = result.available_quantity;
					this.cart_quantity = result.cart_quantity;
					this.is_sold_out = result.is_sold_out;

				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {

			})
		)

	}

	configExitst(arr, value) {
		return arr.some(function (el) {
			return el.configuration_value_id === value;
		});
	}

	designChecked(data, array) {
		array.forEach((element) => {
			if (element.configuration_value_id == data.configuration_value_id) {
				element.select = true;
			} else {
				element.select = false;
			}
		});
		this.selectedConfigurationId = [];
		this.productImagesArray = [];
		this.configData.forEach(element => {
			element.value_array.forEach(valueElement => {
				if (valueElement.select === true) {
					this.selectedConfigurationId.push(valueElement.configuration_value_id)
				}
			});
		});

		this.subProductSelection();

	}

	mobileAttributeChange(dataid, array) {
		array.forEach((element) => {
			if (element.configuration_value_id == dataid) {
				element.select = true;
			} else {
				element.select = false;
			}
		});
		this.selectedConfigurationId = [];
		this.productImagesArray = [];
		this.configData.forEach(element => {
			element.value_array.forEach(valueElement => {
				if (valueElement.select === true) {
					this.selectedConfigurationId.push(valueElement.configuration_value_id)
				}
			});
		});

		this.subProductSelection();
	}

	incrementQuantity() {
		this.minQuantErr = false;
		let available_quantity: number = this.available_quantity - this.cart_quantity;
		if (this.productValue < available_quantity) {
			this.productValue++;
			this.availableQuantErr = false;
		}
	}

	decrementQuantity() {
		this.availableQuantErr = false;
		if (this.productValue > this.selected_subproduct.min_quantity) {
			this.productValue--;
			this.minQuantErr = false;
		}
	}

	async onSubmit() {
		let finalQty: any;

		if (this.productValue >= this.selected_subproduct.min_quantity) {
		}
		else {
			this.minQuantErr = true;
			return;
		}

		let available_quantity: number = this.available_quantity - this.cart_quantity;
		if (this.productValue < available_quantity) {
		}
		else {
			this.availableQuantErr = true;
			return;
		}

		if (this.selected_subproduct.cart_quantity) {
			finalQty = this.selected_subproduct.cart_quantity + this.productValue;
		}
		else {
			finalQty = this.productValue
		}
		await this.cartService.addIntoCart(this.selected_subproduct, finalQty).then((data) => {
			this.toastService.displayToast(data['message'], null, 'success');
			this.modalService.dismissModal()

		});
	}

	openDesignColorModal(dataValues, id, selectedValue, selectedAttribute) {

		let options: any = {
			id: "designAndColor",
			componentProps: {
				configData: dataValues,
				selectedValue: selectedValue,
				config_id: id,
				selected_attribute: selectedAttribute
			}
		};
		options['component'] = DesignAndColorComponent;
		this.modalService.openModal(options)
	}

	attributeChange(data) {
		// code required
		// if (data.en.price_per_unit) {
		//     this.product_price = data.en.price_per_unit;
		// }
	}

	closeModal() {
		this.modalService.dismissModal();
	}

	quantityInput(event) {
		let value = event.target.value;
		this.productValue = +event.target.value;
		if (value === 'e' || value === '+' || value === '-') {
			this.productValue = this.selected_subproduct.min_quantity;
		}
		if (this.productValue == 0 || !this.productValue || this.productValue == null) {
			this.productValue = this.selected_subproduct.min_quantity;
		}

	}

	qtySet() {
		if (this.productValue < this.selected_subproduct.min_quantity) {
			this.productValue = this.selected_subproduct.min_quantity;
		}
	}

	numericOnly(event): boolean {
		this.availableQuantErr = false;
		this.minQuantErr = false;
		let pattern = /^([0-9])$/;
		let result = pattern.test(event.key);
		return result;
	}

}
