import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SharedParamsService } from 'src/app/services/sharedParams.service';
import Swiper from 'swiper';
import { register } from 'swiper/element/bundle';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';

register();

@Component({
	selector: 'app-explore-catelogue',
	templateUrl: './explore-catelogue.component.html',
	styleUrls: ['./explore-catelogue.component.scss'],
})
export class ExploreCatelogueComponent implements OnInit, AfterViewInit {

	private subscription: Subscription = new Subscription();
	public recommendedData = [];
	public showAll: boolean = false;
	public isLoading: boolean = false;

	public categoriesOpts = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 0,
		slidesPerView: 6,
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			360: {
				slidesPerView: 1,
			},
			488: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 6,
			},
			1024: {
				slidesPerView: 6,
			},
			1280: {
				slidesPerView: 6,
			},
			1600: {
				slidesPerView: 6,
			},
			1920: {
				slidesPerView: 6,
			},

		},
	};
	@ViewChild('productsSlides', { static: false }) productsSlides!: ElementRef;
	catelogueSliderEl: any;


	public lastSlide: boolean = false;
	public slideIndex: any;
	public firstSlide: boolean = true;
	public sliderEnd: any;

	private breakpoints = [
		{ width: 320, slides: 1 },
		{ width: 360, slides: 1 },
		{ width: 488, slides: 4 },
		{ width: 768, slides: 6 },
		{ width: 1024, slides: 6 },
		{ width: 1280, slides: 6 },
		{ width: 1600, slides: 6 },
		{ width: 1920, slides: 6 }
	];

	constructor(public router: Router,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translate: TranslateService,
		private navCtrl: NavController,
		public getPlatform: GetPlatformService,
		@Inject(PLATFORM_ID) private platformId: Object,
		public sharedParamsService: SharedParamsService) {
	}

	ngOnInit() {
		if (this.getPlatform.isDesktop)
			this.getExploreCatalogue();
	}



	ngAfterViewInit() {
		setTimeout(() => {
			this.catelogueSliderEl = this.productsSlides ? this.productsSlides.nativeElement : null;

			if (!this.catelogueSliderEl.swiper) {
				Object.assign(this.catelogueSliderEl, this.categoriesOpts);  // Apply slide options
				// this.catelogueSliderEl.initialize();  // Initialize Swiper
				this.catelogueSliderEl.swiper = new Swiper(this.catelogueSliderEl, this.categoriesOpts);
				console.log('Swiper initialized:', this.catelogueSliderEl.swiper);
			}
		}, 500)

	}

	updateSwiperSettings() {
		const slidesPerView = this.getSlidesPerView();
		if (this.catelogueSliderEl.swiper) {
			this.catelogueSliderEl.swiper.params.slidesPerView = slidesPerView;
			this.catelogueSliderEl.swiper.update();
		}
	}

	getSlidesPerView(): number {
		if (!isPlatformServer(this.platformId)) {
			const width = window.innerWidth;
			for (let i = this.breakpoints.length - 1; i >= 0; i--) {
				if (width >= this.breakpoints[i].width) {
					return this.breakpoints[i].slides;
				}
			}
			return this.breakpoints[0].slides; // Default to the smallest number of slides
		}
	}

	viewallcategories() {
		this.showAll = !this.showAll;
	}

	getExploreCatalogue() {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.allGroupList).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					let sliderData = data['data']['result'];
					sliderData.forEach((element, index,) => {
						let obj = {
							product_img: element.group_image_url ? element.group_image_url : "",
							product_description: element.class_description_en ? element.class_description_en : "Adipiscing consectetur do",
							product_descrption_ar: element.class_description_ar ? element.class_description_ar : "Adipiscing consectetur do",
							product_title: element.group_name_en ? element.group_name_en : "",
							product_title_ar: element.group_name_ar ? element.group_name_ar : "",
							product_name: element.group_name_en ? element.group_name_en : "",
							product_name_ar: element.group_name_ar ? element.group_name_ar : "",
							group_id: element.group_id ? element.group_id : 0,
						}
						this.recommendedData.push(obj);
					})
				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	goToCategory(data) {
		let grp_id = data.group_id ? data.group_id : data.class_id;
		let catnameUrl: any = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let currentLang = this.translate.currentLang;
		this.navCtrl.navigateForward('/' + currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
			{
				animated: false,
				"state": {
					"breadcrumb": this.commonService.domDirectionLTR ? data.product_name : data.product_name_ar,
					"url": '/' + currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
				},
			});
	}


	slideToNext() {
		this.catelogueSliderEl.swiper.slideNext();
		this.slideChanged();
	}

	slideToPrev() {
		this.catelogueSliderEl.swiper.slidePrev();
		this.slideChanged();
	}

	async slideChanged() {
		this.slideIndex = await this.catelogueSliderEl.swiper.activeIndex;
		this.sliderEnd = await this.catelogueSliderEl.swiper.isEnd;
		if (this.slideIndex === 0) {
			this.firstSlide = true;
			this.lastSlide = false;
		} else if (this.slideIndex > 0) {
			this.firstSlide = false;
			this.lastSlide = false;
		}
	}

}
