import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isSupported } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { CustomFirebaseAnalyticsProvider } from 'src/app/services/CustomFirebaseAnalyticsProvider';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';
import { WishlistService } from '../../services/wishlist.service';
import { SelectVariantComponent } from '../select-variant/select-variant.component';
import { LoginPage } from './../../pages/login/login.page';
import { CartService } from './../../services/cart.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { ModalService } from './../../services/modal.service';
import { NotifyMeComponent } from './../notify-me/notify-me.component';
@Component({
	selector: 'app-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
	@Input() cardData;
	@Input() productDetailLabel;
	@Input() categories;
	@Input() productlistPage;
	@Input() fromSliderComponent: boolean = false;
	@Input() wishListPage;
	@Input() searchPage: boolean = false;
	@Input() wishListTab: boolean = false;
	@Output() productClicked = new EventEmitter();
	@Output() deleteFromWishList = new EventEmitter();
	private subscription: Subscription = new Subscription();
	public isLoggedIn: boolean = false;
	constructor(public commonService: CommonService,
		public cartService: CartService,
		public modalService: ModalService,
		public router: Router,
		public getPlatformService: GetPlatformService,
		public wishlistService: WishlistService,
		public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
		private dataService: ApiService,
		private toastService: ToastService) {
		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'profileData') {
				this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
			}
			if (data.event == 'logoutCall') {
				this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
			}
		});
	}
	ngOnInit() {
		const token = this.commonService.localStorageGet('accessToken');
		if (token) {
			this.isLoggedIn = true;
		} else {
			this.isLoggedIn = false;
		}
	}

	favProductClicked(event) {
		this.cardData['product_fav'] = true;
		this.cardData['product_liked'] = false;
		event.stopPropagation();
		event.preventDefault();
	}

	gotToProductDetail() {
		this.productClicked.emit(true);
	}

	removeFromWishList() {
		this.deleteFromWishList.emit(true);
	}

	addToCart(data) {
		const token = this.commonService.localStorageGet('accessToken');

		if (!token) {
			let options: any = {
				id: "loginModal",
				backdropDismiss: false
			};
			options['component'] = LoginPage;
			this.modalService.openModal(options);
		}
		else {
			this.cartService.addIntoCart(data, 1);
		}


	}

	addToWishlist(event, product_id, isProductAdded?) {
		event.stopPropagation();
		event.preventDefault();
		if (!isProductAdded) {
			const params = {
				// "product_id": +this.product_id
				"product_id": +product_id,
			}
			this.subscription.add(
				this.dataService.post(URLS.addWishList, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.cardData['is_wishlist_product'] = true;
						this.cardData['wishlist_id'] = data['data']['wishlist_id'];
						isSupported().then(() => {
							this.customFirebaseAnalytics.trackEvent('Add_to_wishlist', +product_id);  //Google Analytics Log In Event
						})

					} else {
						this.cardData['is_wishlist_product'] = false;
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					this.cardData['is_wishlist_product'] = false;

				})
			)
		} else {

			const params = {
				"product_id": +product_id,
				"wishlist_id": this.cardData['wishlist_id'],
			}
			this.cardData['is_wishlist_product'] = false;
			this.subscription.add(
				this.dataService.post(URLS.removeWishList, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.cardData['is_wishlist_product'] = false;
					} else {
						this.cardData['is_wishlist_product'] = true;
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					this.cardData['is_wishlist_product'] = true;

				})
			)
		}

	}

	gotoSelectVariant(product_id, sub_product_id?) {
		let options: any = {
			id: "selectVariantModal",
			backdropDismiss: false
		};
		options['component'] = SelectVariantComponent;
		options['componentProps'] = {
			productId: product_id,
			subProductId: sub_product_id
		};
		this.modalService.openModal(options);

	}

	notifyMeClick(product_id) {
		event.stopPropagation();
		event.preventDefault();

		const token = this.commonService.localStorageGet('accessToken');

		if (!token) {
			let options: any = {
				cssClass: "notify-me-modal",
				backdropDismiss: false,
				id: 'notifyModal'
			};
			options['component'] = NotifyMeComponent;
			options['componentProps'] = {
				productId: product_id
			};
			this.modalService.openModal(options);

		}
		else {
			let userinfo = this.commonService.localStorageGet('userInfo');
			let userData;
			if (userinfo) {
				userData = JSON.parse(userinfo)
			}

			const params = {
				"product_id": product_id,
				"customer_email": userData.customer_email
			}
			this.subscription.add(
				this.dataService.post(URLS.notifyMe, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.toastService.displayToast(data['message'], null, 'success');
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {

				})
			)
		}
	}
}
