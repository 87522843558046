import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { URLS } from "src/app/_config/api.config";
import { ApiService } from "src/app/services/api-data.service";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { ToastService } from "src/app/services/toast.service";
import { WishlistService } from "src/app/services/wishlist.service";
import { CommonService } from "../../services/common.service";
import { ModalService } from "../../services/modal.service";
import { TranslateConfigService } from "../../services/translate-config.service";
import { LoginPage } from "./../../pages/login/login.page";
import { CartService } from "./../../services/cart.service";
import { NotifyMeComponent } from "./../notify-me/notify-me.component";
declare var google;
@Component({
	selector: "app-horizontal-wishlist",
	templateUrl: "./horizontal-wishlist.component.html",
	styleUrls: ["./horizontal-wishlist.component.scss"],
})
export class HorizontalWishlistComponent implements OnInit {
	@Input() array: any;
	@Output() productClicked = new EventEmitter();
	@Output() deleteFromWishList = new EventEmitter();
	private subscription: Subscription = new Subscription();
	public isLoggedIn: boolean = false;
	public isLoading: boolean = false;

	constructor(
		public getPlatformService: GetPlatformService,
		private modalService: ModalService,
		public cartService: CartService,
		public alertController: AlertController,
		private translateConfigService: TranslateConfigService,
		public translate: TranslateService,
		public wishlistService: WishlistService,
		public navCtrl: NavController,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService
	) { }

	ngOnInit() { }

	async removeFromWishList(data) {
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR
				? "Are you sure?"
				: "هل أنت متأكد؟",
			message: this.commonService.domDirectionLTR
				? "You want to remove from wishlist"
				: "تريد إزالة من قائمة الرغبات",
			buttons: [
				{
					text: this.translate.instant('no'),
					role: "cancel",
					handler: () => { },
				},
				{
					text: this.translate.instant('yes'),
					role: "confirm",
					handler: () => {
						this.removeFromWishListData(data);
					},
				},
			],
		});

		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	removeFromWishListData(data) {
		const params = {
			product_id: data.product_id,
			wishlist_id: data.wishlist_id,
		};
		this.subscription.add(
			this.dataService.post(URLS.removeWishList, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						this.getWishList();
					} else {
						this.toastService.displayToast(data["message"], null, "danger");
					}
				},
				(error) => {

				}
			)
		);
	}

	addToCart(data) {
		const token = this.commonService.localStorageGet("accessToken");

		if (!token) {
			let options: any = {
				id: "loginModal",
				backdropDismiss: false,
			};
			options["component"] = LoginPage;
			this.modalService.openModal(options);
		} else {
			this.cartService.addIntoCart(data, 1);
		}
	}

	addToWishlist(event, product_id, isProductAdded?) {
		event.stopPropagation();
		event.preventDefault();
		if (!isProductAdded) {
			const params = {
				// "product_id": +this.product_id
				product_id: +product_id,
			};
			this.subscription.add(
				this.dataService.post(URLS.addWishList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							this.getWishList();
						} else {
							this.toastService.displayToast(data["message"], null, "danger");
						}
					},
					(error) => {

					}
				)
			);
		} else {
			let wishListData = this.array;
			let wishlist_id: any;
			wishListData.forEach((element) => {
				if (element.product_id === +product_id) {
					wishlist_id = element.wishlist_id;
				}
			});
			const params = {
				// "product_id": +this.product_id,
				product_id: +product_id,
				wishlist_id: wishlist_id,
			};

			this.subscription.add(
				this.dataService.post(URLS.removeWishList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							this.getWishList();
						} else {
							this.toastService.displayToast(data["message"], null, "danger");
						}
					},
					(error) => {

					}
				)
			);
		}
	}

	getWishList() {
		const params = {
			page: 1,
			size: 5,
		};
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getWishList, params).subscribe(
				(data) => {
					if (data && data["code"] == 200) {
						let wishListData = data["data"]["result"];
						this.array = [];
						wishListData.forEach((element) => {
							let obj = {
								product_description: element.product_name_en
									? element.product_name_en
									: "",
								product_name: element.product_name_en
									? element.product_name_en
									: "",
								product_name_ar: element.product_name_ar
									? element.category_name_en
									: "",
								category_name_en: element.category_name_en
									? element.product_name_en
									: "",
								category_name_ar: element.category_name_ar
									? element.category_name_ar
									: "",
								brand_name: element.brand_name_en ? element.brand_name_en : "",
								product_currency: "SR",
								product_price: element.price_per_unit
									? element.price_per_unit
									: "",
								price_per_unit: element.price_per_unit
									? element.price_per_unit
									: "",
								final_price: element.final_price ? element.final_price : "",
								product_unit: this.commonService.domDirectionLTR
									? element.unit_name_en
									: element.unit_name_ar,
								product_unit_arb: element.unit_name_ar,
								product_id: element.product_id,
								take_action: true,
								is_wishlist_product: element.is_wishlist_product
									? element.is_wishlist_product
									: false,
								main_product_ref_id: element.main_product_ref_id,
								wishlist_id: element.wishlist_id ? element.wishlist_id : null,
								sold_quantity: element.sold_quantity
									? element.sold_quantity
									: null,
								is_discount_applied: element.is_discount_applied
									? element.is_discount_applied
									: null,
								is_tax_free_product: element.is_tax_free_product
									? element.is_tax_free_product
									: null,
								available_quantity: element.available_quantity
									? element.available_quantity
									: null,
								is_sold_out: element.is_sold_out ? element.is_sold_out : null,
								product_sku: element.product_sku ? element.product_sku : null,
								total_quantity: element.total_quantity
									? element.total_quantity
									: null,
								discount_type: element.discount_type
									? element.discount_type
									: null,
								discount_value: element.discount_value
									? element.discount_value
									: null,
								sub_product_id: element.sub_product_id
									? element.sub_product_id
									: null,
							};

							if (this.getPlatformService.isDesktop) {
								obj["product_img"] = element.web_image_url
									? element.web_image_url
									: "assets/images/no-img1.png";
							} else {
								obj["product_img"] = element.mobile_image_url
									? element.mobile_image_url
									: "assets/images/no-img1.png";
							}

							this.array.push(obj);
						});
						this.isLoading = false;
					} else {
						this.isLoading = false;
					}
				},
				(error) => {
					this.isLoading = false;
				}
			)
		);
	}

	gotToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name_en ? data.product_name_en : data.product_name);
		let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
		if (data.product_id) {
			this.navCtrl.navigateForward(
				"/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
					state: {
						breadcrumb: data.product_description,
						url:
							"/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
					},
				}
			);
		} else {
			this.navCtrl.navigateForward(
				"/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false,
					state: {
						breadcrumb: data.product_description,
						url:
							"/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
					},
				}
			);
		}
	}

	notifyMeClick(product_id) {
		event.stopPropagation();
		event.preventDefault();

		const token = this.commonService.localStorageGet("accessToken");

		if (!token) {
			let options: any = {
				cssClass: "notify-me-modal",
				backdropDismiss: false,
				id: "notifyModal",
			};
			options["component"] = NotifyMeComponent;
			options["componentProps"] = {
				productId: product_id,
			};
			this.modalService.openModal(options);
		} else {
			let userinfo = this.commonService.localStorageGet("userInfo");
			let userData;
			if (userinfo) {
				userData = JSON.parse(userinfo);
			}

			const params = {
				product_id: product_id,
				customer_email: userData.customer_email,
			};
			this.subscription.add(
				this.dataService.post(URLS.notifyMe, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							this.toastService.displayToast(data["message"], null, "success");
						} else {
							this.toastService.displayToast(data["message"], null, "danger");
						}
					},
					(error) => {

					}
				)
			);
		}
	}
}
