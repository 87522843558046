<div class="recommended-container">
	<div class="slider-header">
		<h1 class="header ion-text-center m-0">
			{{ productTitle }}
		</h1>
		<hr *ngIf="!relatedProducts">
		<div *ngIf="!categories && !viewAll && sliderData && sliderData.length > 0" class="view-all"
			(click)="viewAllProducts(sliderData.product_type)">
			{{ "viewAll" | translate }}
		</div>
	</div>
	<!-- recommended, featured, best selling slider for arabic -->
	<swiper-container #productsSlides (ionSlideTransitionEnd)="slideChanged()" [options]="slideOpts"
		*ngIf="!relatedProducts && !categories && !commonService.domDirectionLTR" dir="rtl"
		[slidesPerView]="this.getSlidesPerView()">
		<ng-container *ngIf="sliderData && sliderData.length > 0">
			<swiper-slide *ngFor="let data of sliderData">
				<app-product-card (productClicked)="goToProductDetail(data)" [productDetailLabel]="true"
					[cardData]="data" [fromSliderComponent]="true"></app-product-card>
			</swiper-slide>
			<swiper-slide class="view-all-slide" *ngIf="sliderData.length > 9"
				(click)="viewAllProducts(sliderData.product_type)">
				<div>
					{{ "viewAll" | translate }}
				</div>
			</swiper-slide>
		</ng-container>
		<ng-container *ngIf="sliderData == 0">
			<swiper-slide class="no-record-slide">
				<div class="ion-text-center no-record-found pt-80 pb-80">
					{{ "noRecordFound" | translate}}
				</div>
			</swiper-slide>
		</ng-container>
	</swiper-container>
	<!-- recommended, featured, best selling slider for english -->
	<swiper-container #productsSlides class="ml-10" (ionSlideTransitionEnd)="slideChanged()" [options]="slideOpts"
		*ngIf="!relatedProducts && !categories && commonService.domDirectionLTR" dir="ltr"
		[slidesPerView]="this.getSlidesPerView()">
		<ng-container *ngIf="sliderData && sliderData.length > 0">
			<swiper-slide *ngFor="let data of sliderData">
				<app-product-card (productClicked)="goToProductDetail(data)" [fromSliderComponent]="true"
					[productDetailLabel]="true" [cardData]="data"></app-product-card>

			</swiper-slide>
			<swiper-slide class="view-all-slide" *ngIf="sliderData.length > 9"
				(click)="viewAllProducts(sliderData.product_type)">
				<div>
					{{ "viewAll" | translate }}
				</div>
			</swiper-slide>
		</ng-container>
		<ng-container *ngIf="sliderData == 0">
			<swiper-slide class="no-record-slide">
				<div class="ion-text-center no-record-found pt-80 pb-80">
					{{ "noRecordFound" | translate}}
				</div>
			</swiper-slide>
		</ng-container>
	</swiper-container>
	<swiper-container #productsSlides (ionSlideTransitionEnd)="slideChanged()" [options]="relatedSlideOptions"
		*ngIf="relatedProducts && !commonService.domDirectionLTR" dir="rtl"
		[slidesPerView]="this.getRelatedSlidesPerView()">
		<ng-container *ngIf="sliderData && sliderData.length > 0">
			<swiper-slide class="products-slides" *ngFor="let data of sliderData">
				<app-product-card (productClicked)="goToProductDetail(data)" [fromSliderComponent]="true"
					[productDetailLabel]="true" [cardData]="data"></app-product-card>
			</swiper-slide>
		</ng-container>
		<ng-container *ngIf="sliderData == 0">
			<swiper-slide class="no-record-slide">
				<div class="ion-text-center no-record-found pt-80 pb-80">
					{{ "noRecordFound" | translate}}
				</div>
			</swiper-slide>
		</ng-container>
	</swiper-container>
	<swiper-container #productsSlides (ionSlideTransitionEnd)="slideChanged()" [options]="relatedSlideOptions"
		*ngIf="relatedProducts && commonService.domDirectionLTR" dir="ltr"
		[slidesPerView]="this.getRelatedSlidesPerView()">
		<ng-container *ngIf="sliderData && sliderData.length > 0">
			<swiper-slide class="products-slides" *ngFor="let data of sliderData">
				<app-product-card (productClicked)="goToProductDetail(data)" [fromSliderComponent]="true"
					[productDetailLabel]="true" [cardData]="data"></app-product-card>
			</swiper-slide>
		</ng-container>
		<ng-container *ngIf="sliderData == 0">
			<swiper-slide class="no-record-slide">
				<div class="ion-text-center no-record-found pt-80 pb-80">
					{{ "noRecordFound" | translate}}
				</div>
			</swiper-slide>
		</ng-container>
	</swiper-container>
	<swiper-container #productsSlides [options]="categoriesOpts" *ngIf="categories && !commonService.domDirectionLTR"
		dir="rtl" [slidesPerView]="this.getCateSlidesPerView()">
		<ng-container *ngIf="sliderData && sliderData.length > 0">
			<swiper-slide class="products-slides" *ngFor="let data of sliderData">
				<app-product-card (productClicked)="goToCategoryListing(data)" [fromSliderComponent]="true"
					[categories]="true" [cardData]="data"></app-product-card>
			</swiper-slide>
		</ng-container>
		<ng-container *ngIf="sliderData == 0">
			<swiper-slide class="no-record-slide">
				<div class="ion-text-center no-record-found pt-80 pb-80">
					{{ "noRecordFound" | translate}}
				</div>
			</swiper-slide>
		</ng-container>
	</swiper-container>
	<swiper-container #productsSlides (ionSlideTransitionEnd)="slideChanged()" [ngClass]="[ categories ? 'ml-15' : '']"
		[options]="categoriesOpts" *ngIf="categories && commonService.domDirectionLTR" dir="ltr"
		[slidesPerView]="this.getCateSlidesPerView()">
		<ng-container *ngIf="sliderData && sliderData.length > 0">
			<swiper-slide class="products-slides" *ngFor="let data of sliderData">
				<app-product-card (productClicked)="goToCategoryListing(data)" [fromSliderComponent]="true"
					[categories]="true" [cardData]="data"></app-product-card>
			</swiper-slide>
		</ng-container>
		<ng-container *ngIf="sliderData == 0">
			<swiper-slide class="no-record-slide">
				<div class="ion-text-center no-record-found pt-80 pb-80">
					{{ "noRecordFound" | translate}}
				</div>
			</swiper-slide>
		</ng-container>
	</swiper-container>
	<div (click)="slideToPrev()" *ngIf="sliderData && sliderData.length > 0" [ngClass]="{'related-products-prev-arrow' : relatedProducts && commonService.domDirectionLTR, 
                    'related-products-prev-arrow-arabic' : relatedProducts && !commonService.domDirectionLTR, 
                    'previous-arrow' : !relatedProducts && commonService.domDirectionLTR,
                    'previous-arrow-rtl' : !relatedProducts && !commonService.domDirectionLTR }">
		<ion-icon *ngIf="firstSlide || sliderData && sliderData.length < 5" alt="juman"
			src="assets/icon/slider-left-arrow.svg"></ion-icon>
		<ion-icon class="rotate-arrow" *ngIf="!this.firstSlide && !this.lastSlide" alt="juman"
			src="assets/icon/slider-right-arrow.svg"></ion-icon>
	</div>
	<div (click)="slideToNext()" *ngIf="sliderData && sliderData.length > 0" [ngClass]="{ 'related-products-next-arrow' : relatedProducts && commonService.domDirectionLTR,
                     'related-products-next-arrow-arabic' : relatedProducts && !commonService.domDirectionLTR,
                     'next-arrow' : !relatedProducts && commonService.domDirectionLTR,
                     'next-arrow-rtl' : !relatedProducts && !commonService.domDirectionLTR }">
		<ion-icon *ngIf="firstSlide && sliderData && sliderData.length > 5" alt="juman"
			src="assets/icon/slider-right-arrow.svg"></ion-icon>
		<ion-icon *ngIf="!firstSlide && !sliderEnd && sliderData && sliderData.length > 5" alt="juman"
			src="assets/icon/slider-right-arrow.svg"></ion-icon>
		<ion-icon class="rotate-arrow" *ngIf="(sliderEnd && !firstSlide) || (sliderData && sliderData.length <= 5)"
			alt="juman" src="assets/icon/slider-left-arrow.svg"></ion-icon>
	</div>
</div>