import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../../validators/customvalidators';
import { URLS } from '../../_config/api.config';
import { TwoFactorAuthenticationComponent } from '../../components/two-factor-authentication/two-factor-authentication.component';
import { LoginPage } from '../../pages/login/login.page';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { TermsOfUsePage } from '../terms-of-use/terms-of-use.page';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.page.html',
	styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

	@ViewChild('companyLogoNameElem') companyLogoNameElem: ElementRef;
	@ViewChild('companyCrNoElem') companyCrNoElem: ElementRef;
	@ViewChild('vatCertificateNoElem') vatCertificateNoElem: ElementRef;
	@ViewChild('brandLogoElem') brandLogoElem: ElementRef;
	@ViewChild('nationalAddressFileElem') nationalAddressFileElem: ElementRef;

	public desktopView: boolean = false;
	public countryCodeLabel: boolean = false;
	public isLoading = false;
	private subscription: Subscription = new Subscription();
	public selectedCategory = [];
	public message: any;
	public fileToUpload: any;
	public passwordType = 'password';
	public repeatPasswordType = 'password';
	public isLocationSelected: boolean = false;
	public selected_Product: any;
	public product_value: any;
	public locationData: any = [];
	public is_sample: any;
	public city_id: any;
	public locationSelect = {
		size: 'cover',
		side: 'bottom',
		alignment: 'center',
		cssClass: 'location-popover'
	}
	isCompanyCRNoFile = false;
	isVatCertificateNoFile = false;
	companyCRNoName: any;
	vatCertificateNoName: any;
	companyLogoFile: any;
	brandLogoFile: any;
	companyCRNoFile: any;
	vatCertificateNoFile: any;
	nationalAddressNoFile: any;
	supplierId: any;
	accessToken: any;
	files: any;
	nationalAddressFile: any;
	isNationalAddressFile = false;
	isCheckedList = [];
	termsCheck: boolean = false;
	public validation_messages = {
		email: [
			{ type: 'required', message: 'loginComponent.emailRequired' },
			{ type: 'invalidEmail', message: 'loginComponent.emailInvalid' }
		],
		password: [
			{ type: 'required', message: 'loginComponent.passwordRequired' },
			{ type: 'invalidString', message: 'loginComponent.passwordStringInvalid' },
			{ type: 'invalidPassword', message: 'loginComponent.passwordInvalid' }
		],
		name: [
			{ type: 'required', message: 'registerComponent.nameRequired' },
			{ type: 'invalidName', message: 'registerComponent.nameInvalidString' },
			{ type: 'maxlength', message: 'registerComponent.maxLengthRequired' },
		],
		mobilephone: [
			{ type: 'required', message: 'registerComponent.mobilePhoneRequired' },
			{ type: 'invalidNumber', message: 'registerComponent.invalidPhoneNo' },
			{ type: 'minlength', message: 'registerComponent.invalidPhoneNo' },
			{ type: 'invalidString', message: 'registerComponent.invalidPhoneNo' },
		],
		repeatPassword: [
			{ type: 'required', message: 'registerComponent.repeatPasswordRequired' },
			{ type: 'invalidString', message: 'registerComponent.repeatPasswordInvalidString' },
			{ type: 'invalidPassword', message: 'registerComponent.repeatPasswordInvalid' }
		],
		companyRegistrationNumber: [
			{ type: 'required', message: 'registerComponent.compRegistrationNoRequired' },
			{ type: 'invalidString', message: 'registerComponent.compRegistrationNoInvalidString' },
		],
		location: [
			{ type: 'required', message: 'registerComponent.locationRequired' },
		],
		selectIntrestList: [
			{ type: 'required', message: 'registerComponent.intrestsRequired' }
		],
		vatNumber: [
			{ type: 'required', message: 'companyDetailsComponent.vatNumberRequired' },
			{ type: 'invalidString', message: 'companyDetailsComponent.vatNumbervalid' },
			{ type: 'invalidNumber', message: 'companyDetailsComponent.vatNumbervalid' },
			{ type: 'minlength', message: 'companyDetailsComponent.vatNumbervalid' }

		],
		crNumber: [
			{ type: 'required', message: 'companyDetailsComponent.crNumberRequired' },
			{ type: 'invalidString', message: 'companyDetailsComponent.crNumberValid' },
			{ type: 'invalidNumber', message: 'companyDetailsComponent.crNumberValid' },
			{ type: 'minlength', message: 'companyDetailsComponent.vatNumbervalid' }

		],
		companyCRNoName: [
			{ type: 'required', message: 'companyDetailsComponent.companyCRNoNameError' },
		],
		vatCertificateNoName: [
			{ type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },

		],
		nationalAddressFile: [
			{ type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },
		],
		nationalId: [
			{ type: 'required', message: 'companyDetailsComponent.nationalIdError' },
			{ type: 'invalidNationalId', message: 'companyDetailsComponent.invalidNationalIdError' },
		]
	}

	registerFromGroup: FormGroup;

	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		public navCtrl: NavController,
		public router: Router,
		public platform: Platform,
		private translate: TranslateService,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
	) {
	}

	ngOnInit() {
		this.registerFromGroup = this.initializeRegisterFromGroup();
		this.getProductCategories();
		this.getLocation();
	}

	registrationCheckbox = [];


	selectCheckbox(item) {
		if (item["isChecked"] === false) {
			item["isChecked"] = true;
			this.isCheckedList.push(item);
			this.selectedCategory.push(item.value);
		} else {
			item["isChecked"] = false;
			this.isCheckedList.splice(this.isCheckedList.indexOf(item), 1);
			this.selectedCategory.splice(this.selectedCategory.indexOf(item.value), 1);
		}
		if (this.isCheckedList.length === 0) {
			this.f1.selectIntrestList.setErrors({ 'required': true });
		}

	}

	closeModal() {
		if (this.getPlatformService.isDesktop) {
			this.modalService.dismissModal();
		} else {
			this.navCtrl.navigateForward("/my-account")
		}
	}



	upload(files, fieldname) {
		files.preventDefault();
		files.stopPropagation();

		this.files = files['target']['files'][0].name;
		if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {
			const max_size = 2097152;
			if (files['target']['files'][0].size > max_size) {
				this.message =
					'Maximum size allowed is ' + max_size / 1048576 + 'Mb';
				this.files = null;
				this.fileToUpload = null;
				files.target.value = null;
				this.toastService.displayToast(this.commonService.domDirectionLTR ? this.message : 'الحجم الأقصى المسموح به هو 2 ميغا بايت', null, 'warning');
				return false;
			}

			if (fieldname == 'company-cr-number') {
				this.isCompanyCRNoFile = false;
				if (!isPlatformServer(this.platformId)) {
					setTimeout(() => {
						const setTooltipWidth = this.document.getElementsByClassName(
							'company-cr-no-tooltip',
						) as HTMLCollectionOf<HTMLElement>;
						if (this.companyCrNoElem) {
							const companyCrDivWidth = this.companyCrNoElem.nativeElement.offsetWidth;
							if (setTooltipWidth[0]) {
								setTooltipWidth[0].style.width = companyCrDivWidth + "px";
							}
						}
					}, 500);
				}
			}
			if (fieldname == 'vat-certificate-no') {
				this.isVatCertificateNoFile = false;
				if (!isPlatformServer(this.platformId)) {
					setTimeout(() => {
						const setTooltipWidth = this.document.getElementsByClassName(
							'vat-certificate-no-tooltip',
						) as HTMLCollectionOf<HTMLElement>;
						if (this.vatCertificateNoElem) {
							const companyVatDivWidth = this.vatCertificateNoElem.nativeElement.offsetWidth;
							if (setTooltipWidth[0]) {
								setTooltipWidth[0].style.width = companyVatDivWidth + "px";
							}
						}
					}, 500);
				}
			}
			if (fieldname == 'national-address-file') {
				this.isNationalAddressFile = false;
				if (!isPlatformServer(this.platformId)) {
					setTimeout(() => {
						const setTooltipWidth = this.document.getElementsByClassName(
							'national-address-file-tooltip',
						) as HTMLCollectionOf<HTMLElement>;
						if (this.nationalAddressFileElem) {
							const companyVatDivWidth = this.nationalAddressFileElem.nativeElement.offsetWidth;
							if (setTooltipWidth[0]) {
								setTooltipWidth[0].style.width = companyVatDivWidth + "px";
							}
						}
					}, 500);
				}
			}

			if (fieldname == 'company-cr-number' || fieldname == 'vat-certificate-no' || fieldname == 'national-address-file') {
				let extention = files['target']['files'][0].name.toLowerCase()

				if ((extention).includes(".pdf") || (extention).includes("application/pdf") || (extention).includes(".docx") || (extention).includes("application/docx")) {
					this.message = null;
					if (fieldname == 'company-cr-number') {
						this.companyCRNoFile = files['target']['files'][0];
						this.companyCRNoName = files['target']['files'][0].name;
					} else if (fieldname == 'national-address-file') {
						this.nationalAddressNoFile = files['target']['files'][0];
						this.nationalAddressFile = files['target']['files'][0].name;
					}
					else {
						this.vatCertificateNoFile = files['target']['files'][0];
						this.vatCertificateNoName = files['target']['files'][0].name;

					}
				}
				else {
					this.message = 'Only PDF and DOCX files are allowed';
					this.fileToUpload = null
					this.files = null;
					files.target.value = null;
					this.toastService.displayToast(this.commonService.domDirectionLTR ? 'Only PDF and DOCX files are allowed' : 'يسمح فقط بملفات PDF و docX', null, 'warning');
					return false;
				}

			}
		}
		files.target.value = '';
	}

	getLocation() {
		const params = {
			dropdown: true
		}
		this.subscription.add(
			this.dataService.get(URLS.getLocation, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.locationData = data['data']['result'];
				}
			}, error => {
			})
		)
	}

	getProductCategories() {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getProductCategories).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					let listData = data['data']['result'];;
					listData.forEach(element => {
						let obj = {
							label: element.group_name_en,
							label_ar: element.group_name_ar,
							value: element.group_id,
							isChecked: false
						}
						this.registrationCheckbox.push(obj);
					});
				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	openLoginModal() {
		if (this.getPlatformService.isDesktop) {
			this.closeModal();
			setTimeout(() => {
				let options: any = {
					id: "loginModal",
					backdropDismiss: false,
					componentProps: {
						selected_Product: this.selected_Product,
						product_value: this.product_value,
						is_sample: this.is_sample
					}
				};
				options['component'] = LoginPage;
				this.modalService.openModal(options)
			}, 300);
		} else {
			this.navCtrl.navigateForward('/login');
		}

	}

	get f1() { return this.registerFromGroup.controls; }

	register() {
		this.isCompanyCRNoFile = true;
		this.isVatCertificateNoFile = true;
		this.isNationalAddressFile = true;
		if (this.companyCRNoFile) this.isCompanyCRNoFile = false;
		if (this.vatCertificateNoFile) this.isVatCertificateNoFile = false;
		if (this.nationalAddressFile) this.isNationalAddressFile = false;

		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (!this.companyCRNoFile || !this.vatCertificateNoFile || !this.nationalAddressNoFile) return

		if (this.isCheckedList.length === 0) {
			this.f1.selectIntrestList.setErrors({ 'required': true });
			return;
		}
		if (this.termsCheck != true) {
			this.toastService.displayToast(this.translate.instant('termConditionMsg'), null, 'danger');
			return;
		}
		if (this.registerFromGroup.valid) {
			const params = {
				"customer_name": this.registerFromGroup.controls.name.value ? this.registerFromGroup.controls.name.value.trim() : null,
				"customer_email": this.registerFromGroup.controls.email.value ? this.registerFromGroup.controls.email.value.trim() : null,
				"mobile_no": this.registerFromGroup.controls.mobilephone.value,
				"password": this.registerFromGroup.controls.password.value,
				"group_id": this.selectedCategory,
				"city_id": this.city_id,
				"customer_vat_url": this.vatCertificateNoFile,
				"customer_cr_url": this.companyCRNoFile,
				"national_address_url": this.nationalAddressNoFile,
				"customer_cr_no": this.registerFromGroup.controls.crNumber.value,
				"customer_vat_no": this.registerFromGroup.controls.vatNumber.value,
				"national_id": this.registerFromGroup.controls.nationalId.value
			}

			this.commonService.vatCertificateFile = this.vatCertificateNoFile;
			this.commonService.companyCrFile = this.companyCRNoFile;
			this.commonService.nationalAddressFile = this.nationalAddressNoFile;

			const encryptedData = this.commonService.encryptData(JSON.stringify(params));
			this.commonService.localStorageSet('registration_data', encryptedData);

			const generateOtpParams = {
				"customer_email": this.registerFromGroup.controls.email.value ? this.registerFromGroup.controls.email.value.trim() : null,
				"mobile_no": this.registerFromGroup.controls.mobilephone.value,
				"purpose_type": "REGISTRATION",
				"customer_cr_no": this.registerFromGroup.controls.crNumber.value,
				"customer_vat_no": this.registerFromGroup.controls.vatNumber.value,
				"platform": this.platform.is("mobileweb") || this.platform.is("ipad") ? "WEB" : this.platform.is("android") ? "ANDROID" : this.platform.is("ios") ? "IOS" : "WEB"
			}
			if (this.platform.is("ios") || this.platform.is("android")) {
				generateOtpParams['notification_token'] = this.commonService.cookieGet('notification_token');
			}
			this.isLoading = true;

			const userInfo = {
				"user_name": this.registerFromGroup.controls.email.value ? this.registerFromGroup.controls.email.value.trim() : null,
				"password": this.registerFromGroup.controls.password.value,
				"platform": this.platform.is("mobileweb") || this.platform.is("ipad") ? "WEB" : this.platform.is("android") ? "ANDROID" : this.platform.is("ios") ? "IOS" : "WEB"
			}
			if (this.platform.is("ios") || this.platform.is("android")) {
				userInfo['notification_token'] = this.commonService.cookieGet('notification_token');
			}
			const encryptedUserInfo = this.commonService.encryptData(JSON.stringify(userInfo));


			this.subscription.add(
				this.dataService.post(URLS.generateOtp, generateOtpParams).subscribe(data => {
					if (data['code'] == 200) {
						if (data['data']) {
							if (data['data']['uuid']) {
								this.commonService.localStorageSet('uuid', data['data']['uuid']);
								if (this.getPlatformService.isDesktop) {
									this.closeModal();
								}
								setTimeout(() => {
									this.commonService.localStorageSet('from_register', true);
									let options: any = {
										id: "twoFaModal",
										backdropDismiss: false,
										componentProps: {
											selected_Product: this.selected_Product,
											product_value: this.product_value,
											is_sample: this.is_sample
										}
									};
									options['component'] = TwoFactorAuthenticationComponent;
									this.modalService.openModal(options)
								}, 300);
							}
						}
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
				})
			)

		} else {
			return;
		}
	}


	initializeRegisterFromGroup() {
		return this.formBuilder.group({
			email: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			password: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
			}),
			name: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			repeatPassword: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.matchPassword]),
			}),
			mobilephone: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator, CustomValidator.noWhiteSpaceValidator]),
			}),
			location: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			selectIntrestList: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			companyCRNoName: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			vatCertificateNoName: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			nationalAddressFile: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			vatNumber: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.minLength(15), CustomValidator.noSpaceValidator, CustomValidator.onlyNumberValidator]),
			}),
			nationalId: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.nationalIdValidator]),
			}),
			crNumber: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.minLength(10), CustomValidator.noSpaceValidator, CustomValidator.onlyNumberValidator]),
			}),
		})
	}

	phoneClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	remove(event, fieldname) {
		event.preventDefault();
		event.stopPropagation();
		if (fieldname == 'company-cr-number') {
			this.companyCRNoFile = null;
			this.companyCRNoName = null;
		} else if (fieldname == 'national-address-file') {
			this.nationalAddressNoFile = null;
			this.nationalAddressFile = null;
		} else {
			this.vatCertificateNoFile = null;
			this.vatCertificateNoName = null;
		}
	}

	locationSelected(event) {
		if (event.target.value) {
			this.isLocationSelected = true;
			this.city_id = event.target.value;
		}
	}

	onMobilePhoneFocus() {
		this.countryCodeLabel = true;
	}

	onMobilePhoneBlur() {
		if (!this.f1.mobilephone.value) {
			this.countryCodeLabel = false;
		} else {
			this.countryCodeLabel = true;
		}
	}

	cancelModal() {
		this.modalService.dismissModal();
	}

	gotoTermsConditions() {
		if (this.getPlatformService.isDesktop) {
			let options: any = {
				id: "termsWebModal",
				backdropDismiss: false,
				componentProps: { fromRegisters: true },

			};
			options['component'] = TermsOfUsePage;
			this.modalService.openModal(options)
			// this.router.navigate(['/terms-of-use']);
		}
		else {
			// this.navCtrl.navigateForward('/home/other-menu/terms-of-use', { animated: false });
			let options: any = {
				id: "addressModal",
				backdropDismiss: false,
				componentProps: { fromRegisters: true },
			};
			options['component'] = TermsOfUsePage;
			this.modalService.openModal(options)

		}
	}

}
